<template>
  <section class="home-block">
    <InsideSlider :slides="slides" particle>
      <template #title>
        <h2 class="title">Безопасность <br />у нас в ОСНОВЕ</h2>
      </template>
      <template #bottom>
        <div class="btns d-flex justify-content-center">
          <router-link
            to="/SmartBase_VA_30082024.pdf"
            target="_blank"
            class="btn btn-default"
          >
            Смотреть презентацию
          </router-link>
        </div>
      </template>
    </InsideSlider>
  </section>
</template>

<script>
import InsideSlider from "@/components/InsideSlider.vue";

export default {
  components: { InsideSlider },
  inject: ["mainStore"],
  computed: {
    slides() {
      return this.mainStore.state.data?.safe.slides;
    },
  },
};
</script>

<style scoped>
.home-block {
  padding-bottom: 0;
}

:deep(.inside-slider .swiper-slide) {
  width: 496px;
}

:deep(.inside-slider .item__content) {
  min-height: 400px;
  padding: 32px;
}

@media (min-width: 768px) {
  .title br {
    display: none;
  }
}

@media (max-width: 575px) {
  :deep(.inside-slider .swiper-slide) {
    width: 300px;
  }

  :deep(.inside-slider .item__content) {
    min-height: 200px;
    padding: 16px;
  }
}
</style>
