<template>
  <section class="home-block">
    <InsideSlider />
  </section>
</template>

<script>
import InsideSlider from "@/components/InsideSlider.vue";

export default {
  components: { InsideSlider },
};
</script>
