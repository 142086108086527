<template>
  <div class="near d-none d-lg-block">
    <div
      v-for="item in near"
      :key="item.side"
      class="near__item"
      :class="'-' + item.side"
    >
      <span
        @click="
          if (item.img) {
            $vfm.show({
              component: 'PopupGallery',
              bind: {
                title: item.text.replace(/<\/?[^>]+(>|$)/g, ''),
                img: [{ src: item.img }],
              },
            });
          }
        "
        v-html="item.text"
      />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    near() {
      return [
        {
          side: "n",
          text:
            'Улица Ботаническая вид на <img src="' +
            require("@i/html/home/parks-1-3-t.jpg") +
            '" width="26" height="26" loading="lazy" alt=""> <a href="javascript:void(0)" class="cursor-zoom">главный Ботанический сад и Фондовую Оранжерею</a>',
          img: require("@i/html/home/parks-1-3.jpg"),
        },
        {
          side: "e",
          text:
            '<img src="' +
            require("@i/html/home/parks-2-1-t.jpg") +
            '" width="26" height="26" loading="lazy" alt=""> <a href="javascript:void(0)" class="cursor-zoom">Останкинская Телебашня</a>',
          img: require("@i/html/home/parks-2-1.jpg"),
        },
        {
          side: "s",
          text: "Вид на Малый Ботанический сад",
        },
        {
          side: "w",
          text: "Пересечение парковых зон",
        },
      ];
    },
  },
};
</script>

<style scoped>
.near__item {
  position: absolute;
  text-align: center;
}

.near__item span {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  transform-origin: center;
}

.near__item :deep(a) {
  border-bottom: 1px dashed currentColor;
  font-weight: normal;
  text-decoration: none;
}

body.-notouch .near__item :deep(a:hover) {
  border-color: transparent;
}

.near__item :deep(img) {
  width: 26px;
  height: 26px;
  margin-top: -7px;
  border-radius: 50%;
}

.near__item.-n {
  top: 20px;
  left: 50%;
}

.near__item.-e {
  top: 50%;
  right: -35px;
}

.near__item.-e span {
  transform: translate(-50%, -50%) rotate(90deg);
}

.near__item.-s {
  bottom: 20px;
  left: 50%;
}

.near__item.-w {
  top: 50%;
  left: -35px;
}

.near__item.-w span {
  transform: translate(-50%, -50%) rotate(-90deg);
}
</style>
